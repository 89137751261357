export default function Checkbox(props) {
  const { checked } = props
  let cb = <path id="square-regular" d="M10.639,32H1.277A1.277,1.277,0,0,0,0,33.277v9.363a1.277,1.277,0,0,0,1.277,1.277h9.363a1.277,1.277,0,0,0,1.277-1.277V33.277A1.277,1.277,0,0,0,10.639,32Zm-.16,10.639H1.436a.16.16,0,0,1-.16-.16V33.436a.16.16,0,0,1,.16-.16H10.48a.16.16,0,0,1,.16.16V42.48A.16.16,0,0,1,10.48,42.639Z" transform="translate(0 -32)" fill="#222" />

  if (checked) {
    cb = <path id="check-square-solid" d="M10.639,43.916H1.277A1.277,1.277,0,0,1,0,42.639V33.277A1.277,1.277,0,0,1,1.277,32h9.363a1.277,1.277,0,0,1,1.277,1.277v9.363A1.277,1.277,0,0,1,10.639,43.916ZM5.2,41.308l4.894-4.894a.426.426,0,0,0,0-.6l-.6-.6a.426.426,0,0,0-.6,0L4.894,39.2,3.031,37.338a.426.426,0,0,0-.6,0l-.6.6a.426.426,0,0,0,0,.6l2.766,2.766a.426.426,0,0,0,.6,0Z" transform="translate(0 -32)" fill="#222"/>
  }
  return (
    <svg className='Checkbox' xmlns="http://www.w3.org/2000/svg" width="11.916" height="11.916" viewBox="0 0 11.916 11.916">
      {cb}
    </svg>

  )

}
