const incompleteCountries = [
  'China',
  'Russia',
  'Saudi Arabia',
  'India',
  'South Africa',
  'Mexico',
  'Islamic Development Bank',
  'Australia',
  'Indonesia',
  'Argentina',
]

export default incompleteCountries
